<template>
  <footer
    class="w-100 py-18 px-16 py-sm-24 px-sm-32 hp-bg-color-black-20 hp-bg-color-dark-90"
  >
    <div class="row align-items-center">
      <div class="col-12 col-sm-6">
        <p
          class="hp-badge-text font-weight-semibold mb-0 text-center text-sm-left hp-text-color-dark-30"
        >
        COPYRIGHT ©{{ currentYear }} Hypeople, All rights Reserved
        </p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
