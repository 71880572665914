<template>
  <div class="hp-basket-dropdown-button w-auto px-0 position-relative">

    <b-form-select
      id="buildingListTop"
      v-model="selected"
      :options="options"
      @change="selectBuilding($event)"
      value-field="item"
      text-field="building"
      place
    ></b-form-select>

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormSelectOption } from "bootstrap-vue";

  import InputNumber from "@/view/components/data-entry/input-number/InputNumber.vue";
  import users from '@/logic/functions/users';
  import sweet from '@/logic/functions/sweetAlert';
  import EventBus from '@/logic/functions/EventBus';

export default {
  data: () => {
    return {
      selected: null,
      options: [
        { item: null, building: "Seleccione edificio" },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormSelectOption,
    InputNumber,
  },
  methods: {
  async initialBuildingList(){
      
      //buildings.list()
      users.my_buildings(true)
      .then((response) => {

        Object.entries(response.data.data).forEach((entry) => {
          const [key, value] = entry;

          this.options.push( { item: value.uuid, building: value.name } );
        
        });

      })
      .catch((error) => {
        //console.log(error)
      });

      this.selectBuildings();
    },
    async selectBuilding(event){

      let selectUuid = event;
 
      await users.select_building({ params: {
          "building_uuid": selectUuid,
          "admin": true
        }}).then((response) => {
            localStorage.setItem('home_app_selectBuilding',selectUuid);
            sweet.ToastMsg('','success','Edificio seleccionado');
            this.$router.push({ name: 'get-building', params: { id: selectUuid } })
        }).catch((error) => {
          sweet.ToastMsg('','error','El edificio no se pudo seleccionar');
        });
      
    },
    selectBuildings(){
      this.selected = localStorage.getItem('home_app_selectBuilding') !== null ? localStorage.getItem('home_app_selectBuilding') : null;
    },
  },
  beforeMount() {
   
  },
  mounted() {
    let tmp = this;
    let inicial_building = localStorage.getItem('home_app_selectBuilding') ? localStorage.getItem('home_app_selectBuilding') : JSON.parse(localStorage.getItem('home_app_user')).user.last_building_session; 
    
    users.select_building({ params: {
          "building_uuid": inicial_building,
          "admin": true
        }}).then((response) => {
            localStorage.setItem('home_app_selectBuilding',inicial_building);
        }).catch((error) => {
    });

    this.initialBuildingList();
    this.selected = inicial_building;

    EventBus.$on('BUILDING_TOKEN', function (payLoad) {
      
      tmp.selected = payLoad;
      
    });
  }, 
};
</script>