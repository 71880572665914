import dashboards from "./dashboards"
import applications from "./applications"
import pages from "./pages"
import userInterface from "./user-interface"

export default [
  {
    header: "SuperAdmin",
    children: [
      {
        id: "building",
        title: "Edificios",
        //iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
        navLink: "/admin/configuracion/edificios",
      },
      {
        id: "building_types",
        title: "Tipos de Edificio",
        //iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
        navLink: "/admin/configuracion/edificios/tipos",
      },
      {
        id: "sponsors",
        title: "Sponsors",
        //iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
        navLink: "/admin/configuracion/edificios/sponsors",
      },
      // {
      //   id: "products_and_services",
      //   title: "Productos y Servicios",
      //   //iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
      //   navLink: "/admin/modulos/productos-servicios",
      // },
      {
        id: "pymes",
        title: "Pymes",
        iconSvg: ' ',
        navLink: "/admin/modulos/pymes",
      },

    ]
  },
  {
    header: "Modulos",
    children: [
      {
        id: "noticias",
        title: "Noticias",
        iconSvg: ' ',
        navLink: "/admin/modulos/noticias",
      },
      {
        id: "sales",
        title: "Marketplace",
        iconSvg: ' ',
        navLink: "/admin/modulos/marketplace",
      },
      {
        id: "documents",
        title: "Documentos",
        iconSvg: ' ',
        navLink: "/admin/modulos/documentos",
      },
      {
        id: "communications",
        title: "Comunicados",
        iconSvg: ' ',
        navLink: "/admin/modulos/comunicados",
      },
      {
        id: "eventos",
        title: "Eventos",
        iconSvg: ' ',
        navLink: "/admin/modulos/eventos",
      },
      {
        id: "espacios-reserva",
        title: "Espacios de reserva",
        iconSvg: ' ',
        navLink: "/admin/modulos/reservas",
      },
      {
        id: "visitas",
        title: "Visitas",
        iconSvg: ' ',
        navLink: "/admin/modulos/visitas",
      },
      {
        id: "paquetes",
        title: "Paquetes",
        iconSvg: ' ',
        navLink: "/admin/modulos/paquetes",
      },
      {
        id: "mensajes",
        title: "Mensajes",
        iconSvg: ' ',
        navLink: "/admin/modulos/mensajes",
      },
      {
        id: "pagos",
        title: "Pagos",
        iconSvg: ' ',
        navLink: "/admin/modulos/pagos",
      },
    ]
  },
]