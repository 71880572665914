import { render, staticRenderFns } from "./HeaderUser.vue?vue&type=template&id=fcb3c672"
import script from "./HeaderUser.vue?vue&type=script&lang=js"
export * from "./HeaderUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports